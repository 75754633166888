import {Box, ListItemButton, ListItemProps, SvgIcon, SxProps, Theme} from '@mui/material';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';

import {Link} from '~components/common/Link';
import {textShortener} from '../../utils/miscUtils';

interface MainMenuItemProps extends ListItemProps {
  icon: any;
  text: string;
  linkTo: string;
  /**
   * Adds active styles when the pathname includes at least one of the given values.
   * This does not override the basic check if the first path segment matches the linkTo path.
   */
  onClick: () => void;
  activePaths?: string[];
  sx?: SxProps<Theme>;

  /**
   * Adds a beta tag to the menu item.
   * @default false
   */
  beta?: boolean;
}

const activeBorderWidth = '4px';

export function MainMenuItem({icon, text, linkTo, activePaths, onClick, sx = [], beta}: MainMenuItemProps) {
  const router = useRouter();
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setActive(() => {
      if (activePaths && activePaths.some((pathname) => router.pathname.includes(pathname))) {
        return true;
      } else {
        return router.pathname.startsWith('/' + linkTo.split('/')[1].split('?')[0]);
      }
    });
  }, [router.pathname, linkTo, activePaths]);

  return (
    <ListItemButton
      data-testid={`main-menu-${text}`}
      component='li'
      title={text}
      sx={[
        {
          padding: 0,
          flexGrow: 0,
          '&:is(:hover, :focus, :active, &.Mui-focusVisible)': {
            background: 'none',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      tabIndex={-1}
      onClick={onClick}
    >
      <Link
        href={linkTo}
        passHref
        className={isActive ? 'active' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '13px 0',
          textDecoration: 'none',
          color: 'maddox.globalwhite',
          '&:hover, &:focus, &.active, :focus-visible': {
            outline: 0,
            color: 'maddox.accent',
          },
          '&.active': {
            borderLeft: `${activeBorderWidth} solid currentColor`,
          },
          '&.active > *': {
            marginLeft: `-${activeBorderWidth}`,
          },
        }}
      >
        {beta && (
          <Box
            sx={{
              marginLeft: 1,
              borderRadius: '3px',
              border: '1px solid currentColor',
              paddingInline: '2px',
              lineHeight: '12px',
              fontSize: '8px',
              fontWeight: 600,
              color: 'currentColor',
              position: 'absolute',
              top: 8,
              right: 8,
              backgroundColor: 'secondary.dark',
            }}
          >
            BETA
          </Box>
        )}
        <SvgIcon component={icon} alt='' sx={{fill: 'transparent'}} />
        <Box sx={{fontWeight: 500, fontSize: '12px', lineHeight: '18px', marginTop: 1, whiteSpace: 'nowrap'}}>
          {textShortener(text, 7)}
        </Box>
      </Link>
    </ListItemButton>
  );
}
