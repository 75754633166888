import {Box, BoxProps, IconButton, IconButtonProps} from '@mui/material';
import React, {MouseEventHandler, ReactNode} from 'react';

import {getCommonToolbarButtonStyles, ToolbarElementProps} from '~components/common/toolbar/toolbar.utils';
import {Tooltip} from '~components/common/Tooltip';
import {styled} from 'src/constants/theme';
import {ToolbarDivider} from './ToolbarDivider';

const StyledIconButton = styled(IconButton)<IconButtonProps>(getCommonToolbarButtonStyles);

export interface ToolbarIconButtonProps extends BoxProps, ToolbarElementProps {
  /**
   * Should be a `Icon`, `SvgIcon` or a `@mui/icons-material` SVG icon element.
   */
  icon: ReactNode;
  tooltip: string | undefined;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
}

/**
 * A customized MUI Icon Button intended to be used inside the Toolbar component.
 */
export const ToolbarIconButton = React.forwardRef<HTMLDivElement, ToolbarIconButtonProps>(function ToolbarIconButton(
  {icon, disabled, onClick, hasDivider, tooltip, sx, ...rest}: ToolbarIconButtonProps,
  ref,
) {
  // data-testid property should be applied on the Button and not on the Box around it
  const {['data-testid']: dataTestId, ...restWithoutDataTestId} = rest as any;

  const IconButton = (
    <Box display='inline-block' ref={ref} {...restWithoutDataTestId}>
      <StyledIconButton
        data-testid={dataTestId}
        color='inherit'
        disableRipple
        disabled={disabled}
        onClick={onClick}
        sx={{width: 32, padding: 0, ...sx}}
        title={tooltip}
      >
        {icon}
      </StyledIconButton>
    </Box>
  );

  const wrapWithTooltip = (button: JSX.Element) => {
    return (
      <Tooltip title={tooltip} placement='top-start' arrow disableInteractive>
        {button}
      </Tooltip>
    );
  };

  return (
    <>
      {tooltip ? wrapWithTooltip(IconButton) : IconButton}
      {hasDivider && <ToolbarDivider />}
    </>
  );
});
