import {ListItemIcon, ListItemText, MenuItem, MenuItemProps} from '@mui/material';
import React from 'react';

interface IconMenuItemProps extends MenuItemProps {
  text: string;
  /**
   * Should be a `Icon`, `SvgIcon` or a `@mui/icons-material` SVG icon element.
   */
  icon?: React.ReactNode;
}

/**
 * Customized MUI MenuItem with a leading MUI ListItemIcon.
 */
export const IconMenuItem = React.forwardRef<any, IconMenuItemProps>(function IconMenuItem(
  {icon, text, ...rest}: IconMenuItemProps,
  ref,
) {
  return (
    <MenuItem ref={ref} {...rest}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText disableTypography>{text}</ListItemText>
    </MenuItem>
  );
});
