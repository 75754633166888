import {MoreHoriz} from '@mui/icons-material';
import {Box, List} from '@mui/material';
import {useTranslation} from 'i18n';
import {useRouter} from 'next/router';
import {useEffect, useMemo, useState} from 'react';

import {useFeatureFlags} from '~utils/featureFlags';
import {isInternalEmail} from '~utils/miscUtils';
import {useJuneTrackEvent} from '~hooks/juneAnalytics.hooks';
import {selectUser} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {IconMenuItem} from '~components/common/menu-items/IconMenuItem';
import {ToolbarMenu} from '~components/common/toolbar/ToolbarMenu';
import {styled} from 'src/constants/theme';
import {
  DatabaseIcon,
  DeployIcon,
  HeatmapIcon,
  ModelIcon,
  MonitorIcon,
  ProjectsIcon,
  SettingsIcon,
  TasksIcon,
} from 'src/icons';
import {textShortener} from '../../utils/miscUtils';
import {getRoute, Route, RouteOptions} from '../../utils/routeUtil';
import {MainMenuItem} from './MainMenuItem';

const activeBorderWidth = '4px';

const MaddoxAiLogo = styled('img')(({theme}) => ({
  margin: theme.spacing(4, 2.5, 5, 2.5),
}));

export function MainMenu(): JSX.Element {
  const {t} = useTranslation('mainMenu');
  const {toggleEnableHeatmap, toggleEnableDeployPage} = useFeatureFlags();
  const trackEvent = useJuneTrackEvent();
  const router = useRouter();
  const [moreItemsActive, setMoreItemsActive] = useState(false);
  const moreItemsActivePaths = useMemo(() => [Route.tasks, Route.settingsDevices, Route.projects], []);

  const currentUser = useAppSelector(selectUser);
  const isInteralUser = isInternalEmail(currentUser?.email);
  const routeOptions: RouteOptions = {keepModelIdQueryParam: false};

  useEffect(() => {
    setMoreItemsActive(
      moreItemsActivePaths && moreItemsActivePaths.some((pathname) => router.pathname.includes(pathname)),
    );
  }, [router.pathname, moreItemsActivePaths]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '72px',
        backgroundColor: 'secondary.dark',
      }}
    >
      <MaddoxAiLogo src='/images/maddox-ai-logo.svg' alt='Maddox AI' />
      <List sx={{display: 'flex', flexDirection: 'column', height: '100%', padding: 0}}>
        <MainMenuItem
          onClick={() => trackEvent('main_menu_monitor')}
          linkTo={getRoute(Route.monitor, routeOptions)}
          text={t('monitor')}
          icon={MonitorIcon}
        />
        {toggleEnableHeatmap && (
          <MainMenuItem
            onClick={() => trackEvent('main_menu_heatmap')}
            linkTo={getRoute(Route.heatmap, routeOptions)}
            text={t('heatmap')}
            icon={HeatmapIcon}
            beta
          />
        )}
        {(isInteralUser || toggleEnableDeployPage) && (
          <MainMenuItem
            onClick={() => trackEvent('main_menu_deploy')}
            linkTo={getRoute(Route.deploy, routeOptions)}
            text={t('deploy')}
            icon={DeployIcon}
            beta
          />
        )}
        <MainMenuItem
          onClick={() => trackEvent('main_menu_models')}
          linkTo={getRoute(Route.models, routeOptions)}
          text={t('models')}
          icon={ModelIcon}
          activePaths={['model']}
        />
        <MainMenuItem
          onClick={() => trackEvent('main_menu_archive')}
          linkTo={getRoute(Route.archive, routeOptions)}
          text={t('archive')}
          icon={DatabaseIcon}
        />

        {/* More options menu item */}

        <Box
          className={moreItemsActive ? 'active' : ''}
          sx={{
            marginTop: 'auto',
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: '13px 0',
            textDecoration: 'none',
            color: 'maddox.globalwhite',
            '&:hover, &:focus, &.active, :focus-visible': {
              outline: 0,
              color: 'maddox.accent',
              backgroundColor: 'transparent',
            },
            '&.active': {
              borderLeft: `${activeBorderWidth} solid currentColor`,
            },
            '&.active > *': {
              marginLeft: `-${activeBorderWidth}`,
            },
          }}
        >
          <ToolbarMenu
            id='main-menu-more-options'
            icon={<MoreHoriz />}
            role='button'
            toolbarIconButtonStyles={{
              margin: 0,
              '&:hover': {
                outline: 0,
                color: 'maddox.accent',
                backgroundColor: 'transparent',
              },
            }}
            sx={{
              marginTop: 0,
            }}
          >
            <IconMenuItem
              text={t('tasks')}
              icon={<TasksIcon />}
              onClick={() => router.push(getRoute(Route.tasks, routeOptions))}
            />
            <IconMenuItem
              text={t('settings')}
              icon={<SettingsIcon />}
              onClick={() => router.push(getRoute(Route.settingsDevices, routeOptions))}
            />
            <IconMenuItem
              text={t('projects')}
              icon={<ProjectsIcon />}
              onClick={() => router.push(getRoute(Route.projects, routeOptions))}
            />
          </ToolbarMenu>
          <Box
            sx={{
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '18px',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
          >
            {textShortener('More', 7)}
          </Box>
        </Box>
      </List>
    </Box>
  );
}
