import {Menu, MenuProps} from '@mui/material';
import {ReactNode} from 'react';

import {SxProps} from '~constants/theme';

import {usePopupHandler} from 'src/hooks/misc.hooks';
import {ToolbarElementProps} from './toolbar.utils';
import {ToolbarButton} from './ToolbarButton';
import {ToolbarIconButton} from './ToolbarIconButton';

export interface ToolbarMenuProps extends Omit<MenuProps, 'open'>, ToolbarElementProps {
  id: string;
  /**
   * Should be a `Icon`, `SvgIcon` or a `@mui/icons-material` SVG icon element.
   */
  icon?: ReactNode;
  title?: string;
  disabled?: boolean;
  /**
   * The tooltip only shows when no title is defined (i.e. an ToolbarIconButton is being used)
   */
  tooltip?: string;
  /**
   * @default false
   */
  disableCloseOnItemSelect?: boolean;
  menuPaperRef?: React.Ref<HTMLDivElement>;
  toolbarIconButtonStyles?: SxProps;
  customOnClick?: () => void;
}

/**
 * A customized MUI Menu component intended to be used inside the Toolbar component.
 */
export function ToolbarMenu({
  id,
  icon,
  title,
  disabled,
  children,
  hasDivider,
  tooltip,
  disableCloseOnItemSelect = false,
  menuPaperRef,
  toolbarIconButtonStyles,
  customOnClick,
  ...menuProps
}: ToolbarMenuProps) {
  const {PaperProps, onClose: onCloseFromProps, sx: menuSx, ...rest} = menuProps;
  const {anchorEl, isOpen, handleClick, handleClose} = usePopupHandler<HTMLButtonElement>(customOnClick);

  const handleCloseMenu = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    handleClose();
    if (onCloseFromProps) {
      onCloseFromProps(event, reason);
    }
  };

  return (
    <>
      {title ? (
        <ToolbarButton
          data-testid={id}
          disabled={disabled}
          icon={icon}
          title={title}
          onClick={handleClick}
          aria-controls={isOpen ? id : undefined}
          aria-haspopup='true'
          aria-expanded={isOpen ? 'true' : undefined}
          hasDivider={hasDivider}
          tooltip={tooltip}
        />
      ) : (
        <ToolbarIconButton
          data-testid={id}
          disabled={disabled}
          icon={icon}
          tooltip={tooltip}
          onClick={handleClick}
          aria-controls={isOpen ? id : undefined}
          aria-haspopup='true'
          aria-expanded={isOpen ? 'true' : undefined}
          hasDivider={hasDivider}
          sx={toolbarIconButtonStyles}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        id={id}
        open={isOpen}
        onClose={handleCloseMenu}
        onClick={disableCloseOnItemSelect ? undefined : handleClose}
        PaperProps={{ref: menuPaperRef, ...PaperProps}}
        sx={[
          {
            marginTop: 2,
            '& .MuiListItemIcon-root': {
              color: 'text.primary',
            },
          },
          ...(Array.isArray(menuSx) ? menuSx : [menuSx]),
        ]}
        {...rest}
      >
        {children}
      </Menu>
    </>
  );
}
